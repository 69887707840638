import { combineReducers } from 'redux';

import errors from './errors.js';
import questions from './questions.js';
import answers from './answers.js';
import briefresponse from './briefresponse.js';
import chatgpt from './chatgpt.js';
import marketingRoi from './marketing.js';
import task_template from './tasktemplate.js';

export default combineReducers({
  errors,
  answers,
  questions,
  briefresponse,
  chatgpt,
  marketingRoi,
  task_template,
});
