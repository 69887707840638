import { put, call, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api.js";

import {
  START_TASK_TEMPLATE_SURVEY,
  PENDING_TASK_TEMPLATE_SURVEY,
  SG_START_TASK_TEMPLATE_SURVEY,
  SUBMIT_TASK_TEMPLATE_RESPONSE,
  SG_SUBMIT_TASK_TEMPLATE_RESPONSE,
  ALL_ERRORS,
} from "constants/actions.js";

function* getTaskTemplateSurvey(action) {
  yield put({ type: PENDING_TASK_TEMPLATE_SURVEY });
  try {
    const json = yield call(api.START_TASK_TEMPLATE_SURVEY, action.payload);
    yield put({ type: START_TASK_TEMPLATE_SURVEY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* loadTaskTemplateSurvey() {
  yield takeLatest(SG_START_TASK_TEMPLATE_SURVEY, getTaskTemplateSurvey);
}

function* taskTemplateResponseSubmit(action) {
  yield put({ type: PENDING_TASK_TEMPLATE_SURVEY });
  try {
    const json = yield call(api.SUBMIT_TASK_TEMPLATE_SURVEY, action.payload);
    yield put({ type: SUBMIT_TASK_TEMPLATE_RESPONSE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* submitTaskTemplateResponse() {
  yield takeLatest(
    SG_SUBMIT_TASK_TEMPLATE_RESPONSE,
    taskTemplateResponseSubmit
  );
}

export default function* index() {
  yield all([loadTaskTemplateSurvey(), submitTaskTemplateResponse()]);
}
