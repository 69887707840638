import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers/index.js';
import { rootSaga } from './sagas/index.js';  // Assuming your sagas are exported from 'sagas/index'

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),  // Use a callback to add saga middleware
  devTools: process.env.NODE_ENV === 'development',  // Enable Redux DevTools in development mode
});

console.log('NODE_ENV:', process.env.NODE_ENV);

sagaMiddleware.run(rootSaga);  // Start your root saga

export default store;
