import logo from './logo.svg';
import './App.css';

import 'semantic-ui-css/semantic.min.css';
import { ToastProvider } from 'react-toast-notifications';

import { useRoutes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store.js';
import { routes } from './routes/index.js';
import './i18n.js';


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

function App() {
  const routing = useRoutes(routes);
  return (
    <Provider store={store}>
      <ToastProvider>{routing}</ToastProvider>
    </Provider>
  );
}

export default App;
