import { all, fork } from 'redux-saga/effects';
import Questions from './Questions.js';
import Answers from './Answers.js';
import Start from './Start.js';
import BriefSurvey from './BriefSurvey.js';
import ChatGpt from './ChatGpt.js';
import MarketingRoi from './MarketingRoi.js';
import TaskTemplate from './TaskTemplate.js';

export function* rootSaga() {
  yield all([
    fork(Questions),
    fork(Answers),
    fork(Start),
    fork(BriefSurvey),
    fork(ChatGpt),
    fork(MarketingRoi),
    fork(TaskTemplate),
  ]);
}

export default rootSaga;
