// src/pages/SurveyV3Home.js

import React, { useState, useEffect,useRef  } from "react";
import styled, { keyframes, css } from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Icon, Loader } from "semantic-ui-react";
import { v4 as uuidv4 } from 'uuid';

import Intro from "./Intro";

import {
  SG_START_TASK_TEMPLATE_SURVEY,
  SG_SUBMIT_TASK_TEMPLATE_RESPONSE,
  CLEAR_TASK_TEMPLATE_SURVEY,
  SG_GET_CHAT_GPT_SUMMARY,
  CLEAR_CHAT_GPT_SUMMARY
} from "constants/actions";

const GOAL =
  "We are trying to figure out where the red tape is in our company, and get feedback from each manager on how they can best remove that red tape.";
const NewValidationToken = uuidv4();

const SkeletonLoader = () => (
  <SkeletonCard>
    <SkeletonProgress />
    <SkeletonQuestion />
    <SkeletonFeedback />
  </SkeletonCard>
);

const SurveyV3Home = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const textareaRef = useRef(null);
  const { token, user_token } = useParams();
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [email, setEmail] = useState("");
  const [refToken, setRefToken] = useState("");
  const [userToken, setUserToken] = useState("");
  const [outcomeTemplate, setOutcomeTemplate] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentResponse, setCurrentResponse] = useState("");
  const [userTaskResponse, setUserTaskResponse] = useState([]);
  const [tasksCompleted, setTasksCompleted] = useState(false);
  const [feedbackNotesEnabled, setFeedbackNotesEnabled] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [AIQuestionActive, setAIQuestionActive] = useState(false);
  const [loadingAIQuestion, setLoadingAIQuestion] = useState(false);
  const [aiQuestion, setAIQuestion] = useState("");
  const [aiFeedback, setAIFeedback] = useState();
  const [displayedQuestionIndex, setDisplayedQuestionIndex] = useState(1);
  const [showEmailError, setShowEmailError] = useState(false);
  const { get_task_template } = useSelector(
    (state) => ({
      get_task_template: state.task_template,
    }),
    shallowEqual
  );

  const get_chat_gpt = useSelector((state) => state.chatgpt, shallowEqual);

  const [stage, setStage] = useState(0);


  const buildConversationThread = () => {
    let conversation = "";
    userTaskResponse.map((r) => {
      conversation += `Question: ${r.question}\nResponse: ${r.response}\n`;
    });
    return conversation;
  };


  // Create a function to calculate total questions, if there is aiEnhanched question, add 1 to the total questions
  const getTotalQuestions = (
    userTasks
  ) => {
    let totalQuestions = userTasks?.length;
    userTasks?.map((task) => {
      if (task.aiEnhanced) {
        totalQuestions += 1;
      }
    });
    return totalQuestions;
  }

  useEffect(() => {
    if (token) {
      dispatch({
        type: SG_START_TASK_TEMPLATE_SURVEY,
        payload: `?token=${token}`,
      });
    }
    if (user_token) {
      dispatch({
        type: SG_START_TASK_TEMPLATE_SURVEY,
        payload: `?user-token=${user_token}`,
      });
    }
  }, [dispatch, token, user_token]);

  useEffect(() => {
    if (get_chat_gpt?.chat_gpt_summary?.response && loadingAIQuestion) {
      setLoadingAIQuestion(false);
    }
  }, [get_chat_gpt, loadingAIQuestion]);

  useEffect(() => {
    const questionTasks = get_task_template?.task_template?.[0]?.outcome_template?.task_list?.filter(
      (task) => task.task_type === "question"
    );
    const feedbackNotes = get_task_template?.task_template?.[0]?.outcome_template?.task_list?.find(
      (task) => task.task_type === "feedback_notes"
    )?.selection;
    setUserTasks(questionTasks);
    setFeedbackNotesEnabled(feedbackNotes);
    setOutcomeTemplate(get_task_template?.task_template?.[0]);
    if (get_task_template?.task_template?.[0]?.employee_details?.email) {
      setEmail(get_task_template?.task_template?.[0]?.employee_details?.email);
    }
    if (get_task_template?.task_template?.[0]?.task_ref_id) {
      setRefToken(get_task_template?.task_template?.[0]?.task_ref_id);
    }
    if (get_task_template?.task_template?.[0]?.employee_details?.user_token) {
      setUserToken(get_task_template?.task_template?.[0]?.employee_details?.user_token);
    }
  }, [get_task_template]);


  useEffect(() => {
    const isComplete = get_task_template?.task_template && get_task_template?.task_template?.length > 0 && get_task_template?.task_template?.[0]?.employee_details?.status === "completed";

    if (isComplete) {
      setTasksCompleted(true);
    }
  }, [get_task_template, token, user_token]);

  // useEffect(() => {
  //   if (stage === 0) {
  //     setTimeout(() => setStage(1), 2000);
  //   } else if (stage === 1) {
  //     setTimeout(() => setStage(2), 2000);
  //   }
  // }, [stage]);

  useEffect(() => {
    const handleFocus = () => {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        viewportMeta.setAttribute(
          'content',
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        );
      }
    };

    const handleBlur = () => {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        viewportMeta.setAttribute(
          'content',
          'width=device-width, initial-scale=1.0, user-scalable=yes'
        );
      }
    };

    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('focus', handleFocus);
      textarea.addEventListener('blur', handleBlur);
    }

    // Clean up event listeners on component unmount
    return () => {
      if (textarea) {
        textarea.removeEventListener('focus', handleFocus);
        textarea.removeEventListener('blur', handleBlur);
      }
    };
  }, []);

  useEffect(() => {
      if (!get_chat_gpt?.chat_gpt_summary?.response) {
        return;
      }
      
      const aiQuestion = get_chat_gpt?.chat_gpt_summary?.response.split("**Feedback:**");
      setAIQuestion(aiQuestion[0]);
      if (aiQuestion?.[1]) {
        setAIFeedback(aiQuestion[1]);
      }
  }, [get_chat_gpt]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    dispatch({
      type: SG_START_TASK_TEMPLATE_SURVEY,
      payload: `?token=${token}&email=${e.target.value}`,
    });
  };

  function functionChangeEmail() {
    dispatch({
      type: CLEAR_TASK_TEMPLATE_SURVEY,
    });
    setEmail("");
    setTimeout(() => {
      navigate(`/outcome/${refToken}`);
    }, 500);
  }

  const handlePreviousQuestion = () => {
    // Only allow going back if there are previous questions
    const currentIndex = AIQuestionActive ? currentQuestionIndex : currentQuestionIndex - 1;
    const displayedIndex = displayedQuestionIndex - 1;

    console.log(currentIndex,displayedIndex,AIQuestionActive)
    if (displayedQuestionIndex > 0) {
      if (AIQuestionActive) {
        setAIQuestionActive(false);
        setAIQuestion("");
        setAIFeedback("");
      } else {
        setCurrentQuestionIndex(currentIndex);
      }
      setDisplayedQuestionIndex(displayedIndex);
      
      // Check if the previous question was AI-enhanced and set the appropriate state
      const previousTask = userTaskResponse[currentIndex];

      if (previousTask?.follow_up && displayedIndex > currentIndex*2+1) {
        setAIQuestionActive(true);
        setAIQuestion(previousTask.follow_up?.question);
        setCurrentResponse(previousTask.follow_up?.response);
      } else {
        // Restore the previous response if it exists
        setAIQuestionActive(false);
        setAIQuestion('');
        const previousResponse = userTaskResponse[currentIndex]?.response || "";
        setCurrentResponse(previousResponse);
      }
    }
  };

  const handleNextQuestion = async () => {
    if (!currentResponse) {
      return;
    }

    dispatch({
      type: CLEAR_CHAT_GPT_SUMMARY,
    })

    // If there is already a response for the next question, display that instead.
    // if it is an AI-enhanced question, display the follow-up question instead
    console.log(currentQuestionIndex,displayedQuestionIndex,userTaskResponse?.[currentQuestionIndex])
    const displayedIndex = displayedQuestionIndex + 1;
    const currentIndex = displayedQuestionIndex % 2 === 0 ? currentQuestionIndex + 1 : currentQuestionIndex;
    
    const checkPrevAnswer = (currentIndex)*2 + 1  === displayedIndex && userTaskResponse?.[currentIndex]
    const checkPrevAiAnswer = (currentIndex)*2 + 2 === displayedIndex && userTaskResponse?.[currentIndex]?.follow_up


    console.log(checkPrevAnswer,checkPrevAiAnswer,userTaskResponse,displayedIndex,currentIndex)
    if (checkPrevAnswer || checkPrevAiAnswer) {
      const displayedIndex = displayedQuestionIndex + 1;
      const currentIndex = displayedQuestionIndex % 2 === 0 ? currentQuestionIndex + 1 : currentQuestionIndex;
      
      if(checkPrevAnswer) {
        setAIQuestionActive(false);
        setAIQuestion("");
        setCurrentResponse(userTaskResponse[currentIndex]?.response);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      if(checkPrevAiAnswer) {

      setAIQuestionActive(true);
      setAIQuestion(userTaskResponse[currentIndex]?.follow_up?.question);
      setCurrentResponse(userTaskResponse[currentIndex]?.follow_up?.response);
      }


      setDisplayedQuestionIndex(displayedIndex);
            // Update the responses
            let updated = userTaskResponse;
            updated[currentQuestionIndex] = {
              ...updated[currentQuestionIndex],
              follow_up: {
                response: currentResponse,
                question: userTaskResponse[currentQuestionIndex]?.follow_up?.question,
              }
            };
            setUserTaskResponse(updated);
            setIsFading(true);


            setTimeout(() => {
              setIsFading(false);
            }, 1000);

            return
    }

    let updatedResponses = userTaskResponse;
    let isAiEnhanced = userTasks[currentQuestionIndex]?.aiEnhanced;

    if (updatedResponses?.length >= currentQuestionIndex) {
      // if the AI question is active on the current question index, add the response as a follow
      // up question
      if (updatedResponses[currentQuestionIndex] && AIQuestionActive) {
        updatedResponses[currentQuestionIndex].follow_up = {
          response: currentResponse,
          question: aiQuestion
        };
        setAIQuestionActive(false);
        isAiEnhanced = false;
        } else {
        updatedResponses[currentQuestionIndex] = {
          ...updatedResponses[currentQuestionIndex],
          response: currentResponse,
          question: userTasks[currentQuestionIndex].question,
        };

      }
    }
    
    setUserTaskResponse(updatedResponses);
    setCurrentResponse("");


    if (isAiEnhanced && !userTaskResponse[currentQuestionIndex]?.follow_up?.question) {
      setAIQuestionActive(true);
      setLoadingAIQuestion(true);
      setAIQuestion("");
      setAIFeedback("");
      const history = buildConversationThread();
      const prompt = `Your goal is to come up with a really great follow-up question in order to get the most out of the conversation. 
            The ultimate goal of this conversation as stated by the sender is: ${GOAL}

            Here is the conversation so far:
            ${history}
            
            Ask a great follow-up question to get the best information possible in order to create action from this feedback and accomplish the stated goal.
            Make sure there is a logical flow from the previous question to this one. And make it as conversational as possible.
            ${
              feedbackNotesEnabled
                ? `Following your question, please provide feedback to the person answering the question on their responses to help them give me useful or actionable feedback.
                Any feedback should start with **Feedback:** and then your feedback. Always Capitalize Feedback in the formatting and always include the ** before and after the word Feedback: .Only return the follow-up question and feedback. 
                Do not return the original conversation or any other information.`
                : "Only return the follow-up question. Do not return the original conversation or any other information."
            }`;

      dispatch({
        type: SG_GET_CHAT_GPT_SUMMARY,
        payload: {
          payload: prompt,
          token: NewValidationToken,
        },
      });
    }

    setIsFading(true);
    setTimeout(() => {
      if (displayedQuestionIndex < getTotalQuestions(userTasks)) {
        if (!isAiEnhanced) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          setAIQuestionActive(false);
          setAIQuestion("");
        }
      setDisplayedQuestionIndex(displayedQuestionIndex + 1);
      } else {
        handleSubmit();
        setTasksCompleted(true);
      }
      setIsFading(false);
    }, 1000); // Duration of the fade-out animation
  };

  const handleSubmit = () => {
    dispatch({
      type: SG_SUBMIT_TASK_TEMPLATE_RESPONSE,
      payload: {
        task_template: get_task_template?.task_template?.[0]?.id,
        employee: get_task_template?.task_template?.[0]?.employee_details?.id,
        user_token: user_token || userToken,
        task_response: {
          task_template_id: get_task_template?.task_template?.[0]?.id,
          employee_id: get_task_template?.task_template?.[0]?.employee_details?.id,
          user_token: user_token || userToken,
          email: email,
          task_list: userTaskResponse,
        },
      },
    });
  };



  const AIQuestion = (
    <Card>
      <SmallText>Follow up question</SmallText>
      <Progress>{displayedQuestionIndex} of {getTotalQuestions(userTasks)} questions</Progress>
      {loadingAIQuestion && (
              <SkeletonLoader />
            ) || (
            <div>
              <Question>{aiQuestion}</Question>
              {aiFeedback && (
                <>
            <T4>Feedback Tips</T4>
            <Description>{aiFeedback}</Description>
                </>
              )}
            </div>
      )}
      <TextArea
        placeholder="Type your response here"
        value={currentResponse}
        ref={textareaRef}
        onChange={(e) => setCurrentResponse(e.target.value)}
      />
      <ButtonContainer>
        {displayedQuestionIndex > 1 && (
          <BackButton onClick={handlePreviousQuestion}>Back</BackButton>
        ) || (
          <div style={{ width: "100px" }}></div>
        )}
        <NextButton onClick={handleNextQuestion}>
          {displayedQuestionIndex < getTotalQuestions(userTasks) ? "Next" : "Submit"}
        </NextButton>
      </ButtonContainer>
    </Card>
  )

  const renderCurrentQuestion = (index) => (
    <Card isFading={isFading}>
      <SmallText>Survey Question</SmallText>
      <Progress>{displayedQuestionIndex} of {getTotalQuestions(userTasks)} questions</Progress>

      {aiQuestion ? (
        <div>
        <Question>{aiQuestion}</Question>
        {aiFeedback && (
          <>
      <T4>Feedback Tips</T4>
      <Description>{aiFeedback}</Description>
          </>
        )}
      </div>
      ) : (
      <>
        <Question>{userTasks[index]?.question}</Question>
        
      </>
      )}
      <TextArea
          placeholder="Type your response here"
          ref={textareaRef}
          value={currentResponse}
          onChange={(e) => setCurrentResponse(e.target.value)}
        />
      <ButtonContainer>
      {displayedQuestionIndex > 1 && (
          <BackButton onClick={handlePreviousQuestion}>Back</BackButton>
        ) || (
          <div style={{ width: "100px" }}></div>
        )}
        <NextButton onClick={handleNextQuestion}>
          {displayedQuestionIndex < getTotalQuestions(userTasks) ? "Next" : "Submit"}
        </NextButton>
      </ButtonContainer>
    </Card>
  );

  const renderQuestion = (currentIndex, aiActive) => {
    if (aiActive) {
      return AIQuestion;
    } else {
      return renderCurrentQuestion(currentIndex);
    }
  }


  return (
    <Container>
      {tasksCompleted ? (
        <CompletionCard delay={0.3}>
          <CenteredContainer>
            <Icon name="check circle" size="massive" color="green" style={{marginBottom:20}}/>
            <T1>Survey Complete</T1>
            <Description>
              You have <Blue>successfully completed</Blue> this survey. Your responses have been registered. You may now close this window.
            </Description>
          </CenteredContainer>
        </CompletionCard>
      ) : (
        <>
          {stage<4?<Intro onClick={() => {
                if (user_token) {
                  setStage(4);
                } else {
                  setStage(3)
                }
              }}/>:""}
          {stage === 3 && (
            <>
              <FadeInText>Please begin by entering your email.</FadeInText>
              <InputContainer>
                <Input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  required
                />
                  <SubmitButton disabled={
                    userTasks?.length === 0
                  } onClick={() => {
                    if (userTasks?.length > 0) {
                      setStage(4)
                    } else {
                      // Show error message
                      setShowEmailError(true);
                    }
                  }}>→</SubmitButton>
                </InputContainer>
                {showEmailError && (
                  <RedError>
                    Email not found. Please enter a valid email.
                  </RedError>
                )}
                <CenteredTextClearEmail onClick={functionChangeEmail}>
                  Clear and change Email
                </CenteredTextClearEmail>
            </>
            )}
            
            {stage === 4 && userTasks?.length > 0 && (
            <Content>
              {renderQuestion(currentQuestionIndex, AIQuestionActive)}
            </Content>
          )}
        </>
      )}
    </Container>
  );
};

export default SurveyV3Home;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const RedError = styled.div`
  color: #f15062;
  font-size: 12px;
  margin-top: 10px;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height:100vh;
  width:100%;
  font-family: 'Raleway', sans-serif;
  justify-content:center;

  @media (max-width: 768px) {
  padding:5px;
}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width:800px;
  width: 100%;
`

const FadeInText = styled.div`
  font-size: 2rem;
  margin-bottom: 10px;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const FadeInSubtext = styled.div`
  font-size: 1.5rem;
  margin-bottom: 20px;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const FadeInButton = styled.button`
  font-size: 1.5rem;
  padding: 10px 20px;
  margin-bottom: 20px;
  animation: ${fadeIn} 1s ease-out forwards;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const CenteredTextClearEmail = styled.div`
  font-size: 12px;
  color: #f15062;
  margin-top: 10px;
  cursor: pointer;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  width: 300px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border: none;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: #0056b3;
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  overflow: hidden;
  padding: 30px;
  margin-top: 20px;
  flex-direction: column;
  position: relative;
  animation: ${({ isFading }) =>
  isFading ? css`${fadeOut} 0.5s ease-out forwards` : css`${fadeIn} 0.5s ease-out forwards`};
`;

const Question = styled.div`
  max-width: 100%;
  line-height: 1.7;
  font-size: 16px;
  font-weight: 400;
  color: #2a3039;
  margin-bottom: 15px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  resize: none;
  font-family: 'Raleway', sans-serif;
  line-height:1.5;
`;

const Progress = styled.div`
  font-size: 12px;
  color: #2a3039;
  margin-bottom: 5px;
  position: absolute;
  top: 30px;
  right: 20px;
`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const BackButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #2D70E2;
  background-color: #EBF1FD;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const NextButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const CompletionCard = styled(Card)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards; 
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay;
`;

const CenteredContainer = styled.div`
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  width: 100%;
`;

const T4 = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: #2d70e2;
  margin-top: 10px;
`;

const T1 = styled.div`
  font-size: 28px; 
  font-weight: bold; 
  margin-bottom: 20px; 
  margin-top: 10px; 
  animation: ${fadeIn} 1s ease-out forwards;
`;

const Description = styled.div`
  max-width: 100%; 
  line-height: 1.6; 
  font-size: 14px; 
  font-weight: 400; 
  color: #2a3039; 
  margin-top: 3px;
  margin-bottom: 30px;
`;

// Skeleton Loader Styles
const shimmer = keyframes`
  0% { background-position: -468px 0; }
  100% { background-position: 468px 0; }
`;

const SkeletonCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  border-radius: 4px;
  background-color: #f0f0f0;
  animation: ${shimmer} 1.5s infinite linear forwards;
  background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #f0f0f0 33%);
  background-size: 750px 104px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const SmallText = styled.div`
  font-size: 12px;
  color: #2D70E2;
  font-weight:600;
  text-transform: uppercase;
  margin-bottom:10px;
`;

const Blue = styled.span`
  color: #2D70E2;
  font-weight: 600;
`

const SkeletonElement = styled.div`
  background: #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const SkeletonProgress = styled(SkeletonElement)`
  width: 60%;
  height: 20px;
`;

const SkeletonQuestion = styled(SkeletonElement)`
  width: 80%;
  height: 30px;
`;

const SkeletonFeedback = styled(SkeletonElement)`
  width: 90%;
  height: 15px;
  margin-top: 10px;
`;

const SkeletonTextArea = styled(SkeletonElement)`
  width: 100%;
  height: 30px;
`;

const SkeletonButton = styled(SkeletonElement)`
  width: 100px;
  height: 40px;
  margin-top: 20px;
`;