import {
  GET_ERRORS,
  ALL_ERRORS,
  VALIDATION_ERRORS,
  CLEAR_ERRORS,
} from 'constants/actions.js';

const initialState = {
  msg: {},
  status: {},
  errors: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        msg: action.payload.msg,
        status: action.payload.status,
      };

    case ALL_ERRORS:
      // Use optional chaining to safely access properties
      const message = action.message ?? {};
      const response = message.response ?? {};

      if (message.data) {
        return {
          msg: message,
          errors: response.data ?? {},
          status: response.status ?? {},
        };
      } else if (message) {
        return {
          msg: message,
          errors: response ?? {},
          status: response ?? {},
        };
      } else {
        return {
          msg: message,
          errors: action,
          status: action,
        };
      }

    case VALIDATION_ERRORS:
      // Use optional chaining to safely access properties
      const error = action.error ?? {};
      const errorResponse = error.response ?? {};

      if (error.response) {
        return {
          msg: errorResponse,
          errors: errorResponse.data ?? {},
          status: errorResponse.status ?? {},
        };
      } else {
        return {
          msg: action,
          errors: action,
          status: action,
        };
      }

    case CLEAR_ERRORS:
      return {
        msg: {},
        status: {},
        errors: {},
      };

    default:
      return state;
  }
}
