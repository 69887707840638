import { put, call, all, takeLatest } from 'redux-saga/effects';

import {
  GET_CHAT_GPT_SUMMARY,
  SG_GET_CHAT_GPT_SUMMARY,
  SG_GET_CHAT_GPT_SUMMARY_MULTI,
  PENDING_CHAT_GPT_SUMMARY,
  ALL_ERRORS,
} from 'constants/actions.js';

import * as api from 'constants/api.js';

function* getChatGptSummary(action) {
  yield put({ type: PENDING_CHAT_GPT_SUMMARY });
  try {
    const json = yield call(api.GET_CHAT_GPT_SUMMARY, action.payload);
    yield put({ type: GET_CHAT_GPT_SUMMARY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* loadChatGptSummary() {
  yield takeLatest(SG_GET_CHAT_GPT_SUMMARY, getChatGptSummary);
}

function* getChatGptSummaryMulti(action) {
  yield put({ type: PENDING_CHAT_GPT_SUMMARY });
  try {
    const json = yield call(api.GET_CHAT_GPT_SUMMARY_MULTI, action.payload);
    yield put({ type: GET_CHAT_GPT_SUMMARY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}


export function* loadChatGptSummaryMulti() {
  yield takeLatest(SG_GET_CHAT_GPT_SUMMARY_MULTI, getChatGptSummaryMulti);
}

export default function* index() {
  yield all([loadChatGptSummary(), loadChatGptSummaryMulti()]);
}
