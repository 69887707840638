import {
  PENDING_ANSWERS,
  SET_ANSWERS,
  START_SURVEY,
  // GET_ANSWERS,
  // DELETE_ANSWER,
  FETCH_CULTURE_ANSWER,
  // FETCH_MOOD_ANSWER,
  SELECTED_LEADERSHIP,
  // FETCH_LEADERSHIP_ANSWER,
  // FETCH_PERSONALITY_ANSWER,
  // EDIT_ANSWER,
  // ADD_ANSWER,
  GET_PERSONALITY_ANSWER,
  UPDATE_PERSONALITY_ANSWER,
  ADD_CULTURE_ANSWER,
  ADD_MOOD_ANSWER,
  ADD_TEAM_ANSWER,
  MOOD_UPDATE_COMMENTS,
  ADD_LEADERSHIP_ANSWER,
  RESET_LEADERSHIP,
  ADD_PERSONALITY_ANSWER,
  POST_ANSWER,
  CLEAR_ANSWER,
  CLEAR_ALL,
} from 'constants/actions.js';

const initialState = {
  pending: false,

  all_answers: [], // for submitting optional
  // 0 inactive 1 active (part of this survey token) 2 inprocess 3 completed
  culture_survey_status: 0,
  mood_survey_status: 0,
  leadership_survey_status: 0,
  //
  culture_survey_compliance: 0,
  mood_survey_compliance: 0,
  leadership_survey_compliance: 0,
  //
  team_id: '',
  employee: '',
  employee_name: '',
  employee_picture: '',
  employee_role: 0,
  employee_account: 0,
  time_spent_with: [],
  //
  culture_answers: [],
  culture_max_ques: 0,
  culture_high_ans: 0,
  culture_cur_ques: 0,
  //
  mood_answers: [],
  mood_max_ques: 0,
  mood_high_ans: 0,
  mood_max_ans_nos: 0,
  //
  leader_list: [],
  leader_count: 0,
  leadership_answers: [],
  leadership_max_ques: 0,
  leadership_high_ans: 0,
  leadership_cur_ques: 0,
  //
  personality_answers: [],
  personality_max_ques: 0,
  personality_high_ans: 0,
  personality_cur_ques: 0,
  personality_complete: false,

  personality_survey_status: 0,
  participants_in_personality: 0,
  // 1 culture 2 mood, 3 leadership 4 personality
  curr_direction: 1,
  all_survey_types: [],
  curr_survey_type: 0,
  direction: 0,
  //
  token: '',
  isBrowser: [],
  mobile: [],
  browser: [],
  deviceType: [],
  osVersion: [],
  operating_system: [],
  device: [],
  user_ip: '',
};
// function to check if array are same
// function arrayEquals(a, b) {
//   return (
//     Array.isArray(a) &&
//     Array.isArray(b) &&
//     a.length === b.length &&
//     a.every((val, index) => val === b[index])
//   );
// }
let ansList = [];
let _currentRegQues = 0;
let ans = [];

// leadership_survey_type: 1
// mood_survey_type: 1
// personality_survey_type: 0
// culture_answers: get_answers.culture_answers,
// employee: get_answers,
// token: get_answers,
// device: get_answers,
// mobile: get_answers,
// browser: get_answers,
// operating_system: get_answers,
// user_ip: get_answers,
// culture_survey_compliance: get_answers,
// mood_survey_compliance: get_answers,
// leadership_survey_compliance: get_answers,
// participants_in_personality: get_answers,
// culture_answers: get_answers,
// mood_answers: get_answers,
// leadership_answers: get_answers,
// personality_answers: s

export default function Answers(state = initialState, action) {
  switch (action.type) {
    case PENDING_ANSWERS:
      return {
        ...state,
        pending: true,
      };

    case START_SURVEY:
      const _all_survey_types = [];
      if (action.payload[0]?.culture_survey_compliance) {
        _all_survey_types.push(1);
      }
      if (action.payload[0]?.mood_survey_compliance) {
        _all_survey_types.push(2);
      }
      if (action.payload[0]?.leadership_survey_compliance) {
        _all_survey_types.push(3);
      }
      if (action.payload[0]?.participants_in_personality) {
        _all_survey_types.push(4);
      }

      return {
        ...state,
        pending: false,
        to_delete: action.payload[0]?.to_delete,
        completion_status: action.payload[0]?.completion_status,
        completion_status_text: action.payload[0]?.completion_status_text,
        deleted_on: action.payload[0]?.deleted_on,
        valid_upto: action.payload[0]?.valid_upto,
        token_message: action.payload[0]?.token_message,
        culture_survey_status: action.payload[0]?.culture_survey_type,
        mood_survey_status: action.payload[0]?.mood_survey_type,
        leadership_survey_status: action.payload[0]?.leadership_survey_type,
        personality_survey_status: action.payload[0]?.personality_survey_type,
        employee: action.payload[0]?.employee,
        employee_name: action.payload[0]?.employee_name,
        employee_role: action.payload[0]?.employee_role,
        employee_account: action.payload[0]?.employee_account,
        employee_picture: action.payload[0]?.employee_picture,
        team_id: action.payload[0]?.team_id,
        leader_list: action.payload[0]?.leader_list,
        leader_count: action.payload[0]?.leader_list?.length,
        token: action.payload[0]?.token,
        all_survey_types: _all_survey_types,
        curr_survey_type: _all_survey_types[0],
        culture_survey_compliance: action.payload[0]?.culture_survey_compliance,
        mood_survey_compliance: action.payload[0]?.mood_survey_compliance,
        leadership_survey_compliance:
          action.payload[0]?.leadership_survey_compliance,
        participants_in_personality:
          action.payload[0]?.participants_in_personality,
      };

    case GET_PERSONALITY_ANSWER:
      let curr_que = 1;
      if (action.payload.full_response.personality_cur_ques > 1) {
        curr_que = action.payload.full_response.personality_cur_ques - 1;
      }

      if (action.payload.response_code === 0) {
        return {
          ...state,
          response_code: action.payload.response_code,
          message: action.payload.message,
        };
      } else
        return {
          ...state,
          participants_in_personality:
            action.payload.full_response.participants_in_personality,
          personality_answers: action.payload.full_response.personality_answers,
          personality_complete:
            action.payload.full_response.personality_complete,
          personality_cur_ques: curr_que,
          // personality_high_ans: action.payload.full_response.personality_high_ans,
          personality_max_ques:
            action.payload.full_response.personality_max_ques,
          personality_survey_status:
            action.payload.full_response.personality_survey_status,
        };

    case UPDATE_PERSONALITY_ANSWER:
      return {
        ...state,
      };

    case SET_ANSWERS:
      // culture_high_ans
      let culture_high_ans = state.culture_high_ans;
      if (action.payload.culture_high_ans > state.culture_high_ans) {
        culture_high_ans = action.payload.culture_high_ans;
      }
      // mood_high_ans
      // let _mood_high_ans = state.mood_answers.length;
      // if (action.payload.mood_high_ans > state.mood_high_ans) {
      // mood_high_ans = state.mood_answers.length;
      // }
      // leadership_high_ans
      let leadership_high_ans = state.leadership_high_ans;
      if (action.payload.leadership_high_ans > state.leadership_high_ans) {
        leadership_high_ans = action.payload.leadership_high_ans;
      }
      // personality_high_ans
      // console.log(action.payload)
      return {
        ...state,
        ...action.payload,
        culture_high_ans,
        // mood_high_ans: _mood_high_ans,
        leadership_high_ans,
      };
    case FETCH_CULTURE_ANSWER:
      let culture_selected_answer = {};
      if (
        state.culture_answers.filter((an) => an.id === action.payload.id)[0]
      ) {
        culture_selected_answer = {
          culture_answers: state.culture_answers.filter(
            (ans) => ans.id === action.payload.id
          )[0],
        };
      }
      return {
        ...state,
        [action.payload.id]: culture_selected_answer,
        pending: false,
        culture_cur_ques: action.payload.id,
      };
    case ADD_CULTURE_ANSWER:
      if (
        state.culture_answers.filter((an) => an.id === action.payload.id)[0]
      ) {
        state = {
          ...state,
          culture_answers: state.culture_answers.filter(
            (ans) => ans.id !== action.payload.id
          ),
        };
      }
      ansList = [];
      _currentRegQues = action.payload.id;

      ans = [...state.culture_answers, action.payload];
      ans.map((p) => ansList.push(p.id));
      if (parseInt(action.payload.id + 1) <= state.culture_max_ques) {
        _currentRegQues = action.payload.id + 1;
      }
      let _culture_high_ans = state.culture_high_ans;
      if (action.payload.culture_high_ans > state.culture_high_ans) {
        _culture_high_ans = action.payload.culture_high_ans;
      }

      return {
        ...state,
        culture_answers: [...state.culture_answers, action.payload],
        culture_high_ans: _culture_high_ans,
        culture_cur_ques: _currentRegQues,
      };

    case ADD_TEAM_ANSWER:
      return {
        ...state,
        time_spent_with: action.payload,
      };
    case ADD_MOOD_ANSWER:
      if (state.mood_answers.filter((an) => an.id === action.payload.id)[0]) {
        state = {
          ...state,
          mood_answers: state.mood_answers.filter(
            (ans) => ans.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        mood_answers: [...state.mood_answers, action.payload],
      };

    case MOOD_UPDATE_COMMENTS:
      if (state.mood_answers.filter((an) => an.id === action.payload.id)[0]) {
        state = {
          ...state,
          mood_answers: state.mood_answers.filter(
            (ans) => ans.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        mood_answers: [...state.mood_answers, action.payload],
      };
    case SELECTED_LEADERSHIP:
      return {
        ...state,
        leadership_selected: action.payload.leadership_selected,
        leader_list: action.payload.leader_list,
      };
    case RESET_LEADERSHIP:
      return {
        ...state,
        leadership_high_ans: 0,
        leadership_cur_ques: 0,
        curr_direction: 1,
      };
    // case FETCH_LEADERSHIP_ANSWER:
    //   let leader_selected_answer = {};
    //   if (
    //     state.leadership_answers.filter((an) => an.id === action.payload.id
    //     && an.leader_id === action.payload.leader_id)[0]
    //   ) {
    //     leader_selected_answer = {
    //       leadership_answers: state.leadership_answers.filter(
    //         (ans) => ans.id === action.payload.id
    //         && ans.leader_id === action.payload.leader_id
    //       )[0],
    //     };
    //   }
    //   return {
    //     ...state,
    //     [action.payload.id]: leader_selected_answer,
    //     pending: false,
    //     leadership_cur_ques: action.payload.id,
    //   };

    case ADD_LEADERSHIP_ANSWER:
      // console.log(action.payload)
      if (
        state.leadership_answers.filter(
          (an) =>
            an.row_id === `${action.payload.leader_id}${action.payload.id}`
        )[0]
      ) {
        state = {
          ...state,
          leadership_answers: state.leadership_answers.filter(
            (ans) =>
              ans.row_id !== `${action.payload.leader_id}${action.payload.id}`
          ),
        };
      }
      // ansList = [];
      let _currentLeaderQues = action.payload.id;

      // ans = [...state.leadership_answers, action.payload];
      // ans.map((p) => ansList.push(p.id));

      if (state.leader_count === 1) {
        if (parseInt(action.payload.id + 1) <= state.leadership_max_ques) {
          _currentLeaderQues = action.payload.id + 1;
        }
      }

      let _leadership_high_ans = state.leadership_high_ans;
      if (action.payload.leadership_high_ans > state.leadership_high_ans) {
        _leadership_high_ans = action.payload.leadership_high_ans;
      }
      // console.log(_currentLeaderQues)
      // console.log(_leadership_high_ans)
      return {
        ...state,
        leadership_answers: [...state.leadership_answers, action.payload],
        leadership_high_ans: _leadership_high_ans,
        leadership_cur_ques: _currentLeaderQues,
      };

    case ADD_PERSONALITY_ANSWER:
      if (
        state.personality_answers.filter((an) => an.id === action.payload.id)[0]
      ) {
        state = {
          ...state,
          personality_answers: state.personality_answers.filter(
            (ans) => ans.id !== action.payload.id
          ),
        };
      }
      ansList = [];
      _currentRegQues = action.payload.id;

      ans = [...state.personality_answers, action.payload];
      ans.map((p) => ansList.push(p.id));
      if (parseInt(action.payload.id + 1) <= state.personality_max_ques) {
        _currentRegQues = action.payload.id + 1;
      }
      let _personality_high_ans = state.personality_high_ans;
      if (action.payload.personality_high_ans > state.personality_high_ans) {
        _personality_high_ans = action.payload.personality_high_ans;
      }

      return {
        ...state,
        personality_answers: [...state.personality_answers, action.payload],
        personality_high_ans: _personality_high_ans,
        personality_cur_ques: _currentRegQues,
      };
    case POST_ANSWER:
      return {
        ...state,
        all_answers: [
          ...state.culture_answers,
          ...state.mood_answers,
          ...state.leadership_answers,
          ...state.personality_answers,
          action.payload,
        ],
      };
    case CLEAR_ANSWER:
      return {
        ...state,
        pending: false,

        all_answers: [], // for submitting optional
        // 0 inactive 1 active (part of this survey token) 2 inprocess 3 completed
        culture_survey_status: 0,
        mood_survey_status: 0,
        leadership_survey_status: 0,
        //
        culture_survey_compliance: 0,
        mood_survey_compliance: 0,
        leadership_survey_compliance: 0,
        //
        team_id: '',
        employee: '',
        employee_name: '',
        employee_picture: '',
        employee_role: 0,
        employee_account: 0,
        time_spent_with: [],
        //
        culture_answers: [],
        culture_max_ques: 0,
        culture_high_ans: 0,
        culture_cur_ques: 0,
        //
        mood_answers: [],
        mood_max_ques: 0,
        mood_high_ans: 0,
        mood_max_ans_nos: 0,
        //
        leader_list: [],
        leader_count: 0,
        leadership_answers: [],
        leadership_max_ques: 0,
        leadership_high_ans: 0,
        leadership_cur_ques: 0,
        //
        personality_answers: [],
        personality_max_ques: 0,
        personality_high_ans: 0,
        personality_cur_ques: 0,
        personality_complete: false,

        personality_survey_status: 0,
        participants_in_personality: 0,
        // 1 culture 2 mood, 3 leadership 4 personality
        curr_direction: 1,
        all_survey_types: [],
        curr_survey_type: 0,
        direction: 0,
        //
        token: '',
        isBrowser: [],
        mobile: [],
        browser: [],
        deviceType: [],
        osVersion: [],
        operating_system: [],
        device: [],
        user_ip: '',
      };

    case CLEAR_ALL:
      return {
        ...state,
        pending: false,

        all_answers: [], // for submitting optional
        // 0 inactive 1 active (part of this survey token) 2 inprocess 3 completed
        culture_survey_status: 0,
        mood_survey_status: 0,
        leadership_survey_status: 0,
        //
        culture_survey_compliance: 0,
        mood_survey_compliance: 0,
        leadership_survey_compliance: 0,
        //
        team_id: '',
        employee: '',
        employee_name: '',
        employee_picture: '',
        employee_role: 0,
        employee_account: 0,
        time_spent_with: [],
        //
        culture_answers: [],
        culture_max_ques: 0,
        culture_high_ans: 0,
        culture_cur_ques: 0,
        //
        mood_answers: [],
        mood_max_ques: 0,
        mood_high_ans: 0,
        mood_max_ans_nos: 0,
        //
        leader_list: [],
        leader_count: 0,
        leadership_answers: [],
        leadership_max_ques: 0,
        leadership_high_ans: 0,
        leadership_cur_ques: 0,
        //
        personality_answers: [],
        personality_max_ques: 0,
        personality_high_ans: 0,
        personality_cur_ques: 0,
        personality_complete: false,

        personality_survey_status: 0,
        participants_in_personality: 0,
        // 1 culture 2 mood, 3 leadership 4 personality
        curr_direction: 1,
        all_survey_types: [],
        curr_survey_type: 0,
        direction: 0,
        //
        token: '',
        isBrowser: [],
        mobile: [],
        browser: [],
        deviceType: [],
        osVersion: [],
        operating_system: [],
        device: [],
        user_ip: '',
      };
    default:
      return state;
  }
}
