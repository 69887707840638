import {
  PENDING_QUESTIONS,
  GET_CULTURE_QUESTIONS,
  GET_MOOD_QUESTIONS,
  GET_LEADERSHIP_QUESTIONS,
  GET_PERSONALITY_QUESTIONS,
  GET_PREVIOUS_RESPONSE,
  PENDING_ALL_MODULAR_QUESTIONS,
  GET_ALL_MODULAR_QUESTIONS,
  GET_MODULAR_QUESTIONS,
  PREVIOUS_RESPONSE_PENDING,
  PENDING_CULTURE_QUESTIONS,
  PENDING_MOOD_QUESTIONS,
  PENDING_LEADERSHIP_QUESTIONS,
  PENDING_PERSONALITY_QUESTIONS,
  FETCH_QUESTIONS,
  CLEAR_QUESTIONS,
  CLEAR_ALL,
} from 'constants/actions.js';

const initialState = {
  culture_questions: [],
  mood_questions: [],
  leadership_questions: [],
  personality_questions: [],
  modular_questions: [],
  previous_response: [],
  pending: false,
  pending_culture_questions: false,
  pending_mood_questions: false,
  pending_leadership_questions: false,
  pending_modular_questions: false,
  pending_personality_questions: false,
  previous_response_pending: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PENDING_QUESTIONS:
      return {
        ...state,
        pending: true,
      };
    case PENDING_CULTURE_QUESTIONS:
      return { ...state, pending_culture_questions: true, pending: true };
    case PENDING_MOOD_QUESTIONS:
      return { ...state, pending_mood_questions: true, pending: true };
    case PENDING_LEADERSHIP_QUESTIONS:
      return { ...state, pending_leadership_questions: true, pending: true };
    case PENDING_ALL_MODULAR_QUESTIONS:
      return { ...state, pending_modular_questions: true, pending: true };
    case PENDING_PERSONALITY_QUESTIONS:
      return { ...state, pending_personality_questions: true, pending: true };
    case PREVIOUS_RESPONSE_PENDING:
      return { ...state, previous_response_pending: true, pending: true };
    case GET_CULTURE_QUESTIONS:
      return {
        ...state,
        pending_culture_questions: false,
        culture_questions: action.payload,
        pending: false,
      };
    case GET_MOOD_QUESTIONS:
      return {
        ...state,
        pending_mood_questions: false,
        mood_questions: action.payload,
        pending: false,
      };
    case GET_LEADERSHIP_QUESTIONS:
      return {
        ...state,
        pending_leadership_questions: false,
        leadership_questions: action.payload,
        pending: false,
      };
    case GET_ALL_MODULAR_QUESTIONS:
      const _modularQues = state.modular_questions;
      _modularQues[action.payload.component] = action.payload;
      return {
        ...state,
        pending_modular_questions: false,
        modular_questions: _modularQues,
        pending: false,
      };

    case GET_MODULAR_QUESTIONS:
      return {
        ...state,
        pending_modular_questions: false,
        modular_questions: action.payload?.[0]?.additional_type,
        pending: false,
      };
    case GET_PERSONALITY_QUESTIONS:
      return {
        ...state,
        pending_personality_questions: false,
        personality_questions: action.payload,
        pending: false,
      };

    case GET_PREVIOUS_RESPONSE:
      return {
        ...state,
        previous_response_pending: false,
        previous_response: action.payload,
        pending: false,
      };

    case FETCH_QUESTIONS:
      return {
        ...state,
        selected: action.payload,
        pending: false,
      };

    case CLEAR_QUESTIONS:
      return {
        ...state,
        culture_questions: [],
        mood_questions: [],
        leadership_questions: [],
        personality_questions: [],
        previous_response: [],
        pending: false,
        pending_culture_questions: false,
        pending_mood_questions: false,
        pending_leadership_questions: false,
        pending_personality_questions: false,
        previous_response_pending: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        culture_questions: [],
        mood_questions: [],
        leadership_questions: [],
        personality_questions: [],
        previous_response: [],
        pending: false,
        pending_culture_questions: false,
        pending_mood_questions: false,
        pending_leadership_questions: false,
        pending_personality_questions: false,
        previous_response_pending: false,
      };
    default:
      return state;
  }
}
