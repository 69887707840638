import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as api from 'constants/api.js';

import {
  PENDING_SURVEY,
  START_BRIEF_SURVEY,
  SG_START_BRIEF_SURVEY,
  GET_MODULAR_QUESTIONS,
  POST_BRIEF_RESPONSE,
  SG_POST_BRIEF_RESPONSE,
  SG_UPDATE_BRIEF_RESPONSE,
  UPDATE_BRIEF_RESPONSE,
  SG_UPDATE_BRIEF_RESPONSE_ANSWERS,
  // GET_BRIEF_MODULAR_QUESTIONS,
  // SG_GET_BRIEF_MODULAR_QUESTIONS,
  UPDATE_BRIEF_RESPONSE_ANSWERS,
  ALL_ERRORS,
} from 'constants/actions.js';

function* getBriefSurveQuestions(action) {
  yield put({ type: PENDING_SURVEY });
  try {
    const json = yield call(api.GET_BRIEF_SURVEY, action.payload);
    yield put({ type: START_BRIEF_SURVEY, payload: json.data });
    yield put({ type: GET_MODULAR_QUESTIONS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* loadBriefSurveyQuestions() {
  yield takeLatest(SG_START_BRIEF_SURVEY, getBriefSurveQuestions);
}

function* briefSurveyResponsePost(action) {
  yield put({ type: PENDING_SURVEY });
  try {
    const json = yield call(api.POST_BRIEF_RESPONSE, action.payload);
    yield put({ type: POST_BRIEF_RESPONSE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.response });
  }
}

export function* postPostSurveyResponse() {
  yield takeLatest(SG_POST_BRIEF_RESPONSE, briefSurveyResponsePost);
}

function* getUpdateResponse(action) {
  yield put({ type: PENDING_SURVEY });
  try {
    const json = yield call(api.UPDATE_BRIEF_RESPONSE, action.payload);
    yield put({
      type: UPDATE_BRIEF_RESPONSE,
      payload: json.data.stored_response,
    });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* UpdateResponsePost() {
  yield takeLatest(SG_UPDATE_BRIEF_RESPONSE, getUpdateResponse);
}

function* fetchUpdateResponse(action) {
  yield put({ type: PENDING_SURVEY });
  try {
    const json = yield call(api.UPDATE_BRIEF_RESPONSE, action.payload);
    yield put({ type: UPDATE_BRIEF_RESPONSE_ANSWERS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* UpdateResponseFetch() {
  yield takeLatest(SG_UPDATE_BRIEF_RESPONSE_ANSWERS, fetchUpdateResponse);
}

// function* fetchModularQuestion(action) {
//   yield put({ type: PENDING_SURVEY });
//   try {
//     const json = yield call(api.GET_BRIEF_MODULAR_QUESTIONS, action.payload);
//     yield put({ type: GET_BRIEF_MODULAR_QUESTIONS, payload: json.data });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, message: e });
//   }
// }

// export function* ModularQuestionFetch() {
//   yield takeLatest(SG_GET_BRIEF_MODULAR_QUESTIONS, fetchModularQuestion);
// }

export default function* index() {
  yield all([
    loadBriefSurveyQuestions(),
    postPostSurveyResponse(),
    UpdateResponsePost(),
    UpdateResponseFetch(),
    // ModularQuestionFetch(),
  ]);
}
