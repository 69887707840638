import { put, call, all, takeLatest, takeEvery } from 'redux-saga/effects';
import * as api from 'constants/api.js';

import {
  // GET_ANSWERS,
  // LOAD_ANSWERS,
  // DELETE_ANSWER,
  // PULL_ANSWER,
  // FETCH_ANSWER,
  // UPDATE_ANSWER,
  // EDIT_ANSWER,
  // ADD_CULTURE_ANSWER,
  // ADD_MOOD_ANSWER,
  // ADD_LEADERSHIP_ANSWER,
  // ADD_PERSONALITY_ANSWER,
  GET_PERSONALITY_ANSWER,
  SG_GET_PERSONALITY_ANSWER,
  UPDATE_PERSONALITY_ANSWER,
  SG_UPDATE_PERSONALITY_ANSWER,
  START_PERSONALITY_ANSWER,
  SG_START_PERSONALITY_ANSWER,
  ADD_ANSWER,
  SAVE_ANSWER,
  POST_ANSWER,
  SUBMIT_ANSWER,
  // CLEAR_ANSWER,
  // CLEAR_ALL,
  ALL_ERRORS,
} from 'constants/actions.js';

function* fetchPersonality(action) {
  try {
    const json = yield call(api.GET_PERSONALITY_ANSWER, action.payload);
    yield put({ type: GET_PERSONALITY_ANSWER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* answerPersonality() {
  yield takeLatest(SG_GET_PERSONALITY_ANSWER, fetchPersonality);
}

function* updatePersonality(action) {
  // console.log(action.payload)
  try {
    const json = yield call(
      api.UPDATE_PERSONALITY_ANSWER,
      action.payload.token,
      action.payload
    );

    yield put({ type: UPDATE_PERSONALITY_ANSWER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* personalityUpdate() {
  yield takeLatest(SG_UPDATE_PERSONALITY_ANSWER, updatePersonality);
}

function* startPersonality(action) {
  try {
    const json = yield call(api.START_PERSONALITY_ANSWER, action.payload);
    yield put({ type: START_PERSONALITY_ANSWER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* personalityStart() {
  yield takeEvery(SG_START_PERSONALITY_ANSWER, startPersonality);
}

function* addAnswer(action) {
  try {
    const json = yield call(api.POST_ANSWER, action.payload);

    yield put({ type: ADD_ANSWER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* answerAdd() {
  yield takeLatest(SAVE_ANSWER, addAnswer);
}

function* postAnswer(action) {
  try {
    const json = yield call(api.POST_ANSWER, action.payload);

    yield put({ type: POST_ANSWER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* answerSubmit() {
  yield takeLatest(SUBMIT_ANSWER, postAnswer);
}

export default function* index() {
  yield all([
    answerPersonality(),
    personalityUpdate(),
    personalityStart(),
    answerAdd(),
    answerSubmit(),
  ]);
}
