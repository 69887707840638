const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

const sendToBackend = (metric) => {
  const body = JSON.stringify(metric);
  console.log(body);
//   const url = 'http://localhost:8000/web-vitals'; // Update this URL to match your backend

//   // Use navigator.sendBeacon if available for better performance
//   if (navigator.sendBeacon) {
//     navigator.sendBeacon(url, body);
//   } else {
//     fetch(url, {
//       body,
//       method: 'POST',
//       keepalive: true,
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//   }
};

// export default reportWebVitals;

const defaultExport = (metric) => reportWebVitals(sendToBackend);

export default defaultExport;
