
import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";

import Chat from 'assets/images/chat.png'



const Intro = ({ data, onClick }) => {


    return (
        <Container>
            <Content>
                Welcome to <Blue>innerlogic</Blue>, a safe and secure platform for collecting culture feedback.
                <Image src={Chat} alt="Image description" />
            </Content>

            <ButtonArea>
                <Button onClick={()=>onClick()}>Begin survey</Button>
            </ButtonArea>
        </Container>
    )

}

export default Intro;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway', sans-serif;
`

const Content = styled.div`
    max-width: 600px;
    width: 100%;
    padding: 40px;
    border-radius:20px;
    background-color: #F8FAFF;
    font-size:44px;
    line-height: 1.5;
    font-weight:500;
    color:#2A3039;

    @media(max-width:768px){
        font-size:24px;
    }
`

const Image = styled.img`
  width: 100px;
  display: inline;
  vertical-align: middle;
  margin-left: 0.25em;
  margin-right: 0.25em;

    @media(max-width:768px){
        width: 50px;
    }
`;

const Blue = styled.span`
    color: #2d70e2;
    font-weight:700;
`

const ButtonArea = styled.div`
    margin-top: 20px;
    display: flex;
    width:100%;
    justify-content: flex-end;
    max-width:600px;
    padding:20px;
`

const Button = styled.button`
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    padding: 10px 20px;
    color: white;
    background-color: #2d70e2;
    border-radius: 8px;
    border: none;
    cursor: pointer;
`