import {
  PENDING_SURVEY,
  START_BRIEF_SURVEY,
  NEW_VALIDATION_TOKEN,
  RESET_VALIDATION_TOKEN,
  UPDATE_STORED_RESPONSE,
  UPDATE_BRIEF_RESPONSE,
  UPDATE_BRIEF_RESPONSE_ANSWERS,
  POST_BRIEF_RESPONSE,
  CLEAR_ANSWER,
  CLEAR_ALL,
} from 'constants/actions.js';

const initialState = {
  id: null,
  pending: false,
  validation_token: false,
  modular_question: [],

  surveyComponents: [],
  survey_token: false,
  debrief_schedule: false,
  employee: false,
  survey_status: 0,
  //
  questions: [],
  response: [],
  //
  intro: [],
  short: [],
  title: [],
  intro_completed: false,
  intro2_completed: false,
  valid_upto: false,
  employee_name: false,
  employee_survey_spec: {},
  //
  total_question_map: {},
  total_categories: 0,
  total_questions: 0,

  feedback: [],
  modular_response: {},
  survey_personality: {},
  in_survey_personality: false,
  showing_stored_response: false,
  // stored data
  // answers: localStorage.getItem("answers"),
  // visual_question : localStorage.getItem("visual_question"),
  // current_question: localStorage.getItem("current_question"),
  // current_answer: localStorage.getItem("current_answer"),
  // high_answer: localStorage.getItem("high_answer"),
  // complete: false,

  answers: {},
  visual_question: 0,
  current_question: 0,
  current_answer: 0,
  high_answer: 0,
  complete: false,
  //
  isBrowser: [],
  mobile: [],
  browser: [],
  deviceType: [],
  osVersion: [],
  operating_system: [],
  device: [],
  user_ip: '',
  questionSequenceMap: {},
};

export default function BriefReponse(state = initialState, action) {
  // console.log(action.payload)
  switch (action.type) {
    case PENDING_SURVEY:
      return {
        ...state,
        pending: false,
      };
    case START_BRIEF_SURVEY:
      return {
        ...state,
        pending: false,
        id: action.payload[0]?.id,
        response: action.payload?.[0],
        to_delete: action.payload[0]?.to_delete,
        valid_upto: action.payload[0]?.valid_upto,
        deleted_on: action.payload[0]?.deleted_on,
        survey_status: action.payload[0]?.survey_status,
        surveyComponents: action.payload?.[0].organization_info.survey_sequence,
        token_message: action.payload[0]?.token_message,
        intro: action.payload?.[0]?.questions?.intro,
        short: action.payload?.[0]?.questions?.short,
        questions: action.payload?.[0]?.questions?.questions,
        total_questions: action.payload?.[0]?.questions?.questions?.length,
        title: action.payload?.[0]?.questions?.title,
        employee: action.payload?.[0]?.employee,
        employee_name: action.payload?.[0]?.employee_name,
        survey_token: action.payload?.[0]?.token,
        debrief_schedule: action.payload?.[0]?.debrief_schedule,
        employee_survey_spec: action.payload?.[0]?.employee_survey_spec,
        questionSequenceMap: action.payload?.[0]?.questionSequenceMap,
        modular_response: action.payload?.[0]?.modular_response,
        validation_token: action.payload?.[0]?.validation_token,
        modular_questions: action.payload?.[0]?.additional_type,
      };
    case UPDATE_STORED_RESPONSE:
      return {
        ...state,
        showing_stored_response: action.payload,
        pending: false,
      };
    case UPDATE_BRIEF_RESPONSE:
      // if (action.payload?.validation_token) {
      //    localStorage.setItem("validation_token", action.payload?.validation_token);
      // }

      return {
        ...state,
        ...action.payload,
        pending: false,
      };
    case UPDATE_BRIEF_RESPONSE_ANSWERS:
      return {
        ...state,
        ...action.payload,
        // response: action.payload,
        pending: false,
      };
    case NEW_VALIDATION_TOKEN:
      return {
        ...state,
        validation_token: action.payload,
        pending: false,
      };

    case RESET_VALIDATION_TOKEN:
      localStorage.clear();
      return {
        ...state,
        validation_token: false,
        pending: false,
      };
    case POST_BRIEF_RESPONSE:
      return {
        ...state,
        ...action.payload,
        pending: false,
      };
    case CLEAR_ANSWER:
      return {
        ...state,
        survey_token: false,
        validation_token: false,
        debrief_schedule: false,
        employee: false,
        answers: [],
        //
        questions: [],
        response: [],
        intro: [],
        title: [],
        valid_upto: false,
        employee_name: false,
        total_questions: 0,
        //
        visual_question: 0,
        current_question: 0,
        current_answer: 0,
        high_answer: 0,
        complete: false,
        //
        isBrowser: [],
        mobile: [],
        browser: [],
        deviceType: [],
        osVersion: [],
        operating_system: [],
        device: [],
        user_ip: '',
      };

    case CLEAR_ALL:
      return {
        ...state,
        survey_token: false,
        validation_token: false,
        debrief_schedule: false,
        employee: false,
        answers: [],
        modular_question: [],
        surveyComponents: [],
        //
        questions: [],
        response: [],
        intro: [],
        title: [],
        valid_upto: false,
        employee_name: false,
        total_questions: 0,
        //
        visual_question: 0,
        current_question: 0,
        current_answer: 0,
        high_answer: 0,
        complete: false,
        //
        isBrowser: [],
        mobile: [],
        browser: [],
        deviceType: [],
        osVersion: [],
        operating_system: [],
        device: [],
        user_ip: '',
      };
    default:
      return state;
  }
}
