import {
  START_TASK_TEMPLATE_SURVEY,
  PENDING_TASK_TEMPLATE_SURVEY,
  SUBMIT_TASK_TEMPLATE_RESPONSE,
  CLEAR_TASK_TEMPLATE_SURVEY,
} from "constants/actions.js";

const initialState = {
  pending: false,
  task_template: {},
};

export default function TaskTemplate(state = initialState, action) {
  switch (action.type) {
    case PENDING_TASK_TEMPLATE_SURVEY:
      return {
        ...state,
        pending: true,
      };
    case START_TASK_TEMPLATE_SURVEY:
      return {
        ...state,
        pending: false,
        task_template: action.payload,
      };
    case SUBMIT_TASK_TEMPLATE_RESPONSE:
      return {
        ...state,
        pending: false,
        task_template: action.payload,
      };
    case CLEAR_TASK_TEMPLATE_SURVEY:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
