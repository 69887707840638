import Image from 'assets/images/live-site.svg';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  margin-top: 100px;
  justify-content: center;
`;

const Card = styled.div`
  width: 600px;
  height: 462px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 #ececec;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;
const IMG = styled.img`
  width: 170px;
  height: 170px;
`;

const Title = styled.div`
  font-size: 23px;
  font-weight: bold;
  text-align: center;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom: 8px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.31;
  text-align: center;
  color: #4a4a4a;
  font-family: 'Red Hat Display', sans-serif;
  max-width: 472px;
`;

const Health = () => {
  return (
    <Container>
      <Card>
        <IMG src={Image} />
        <div>
          <Title>Cool!</Title>
          <Text>It's all good</Text>
        </div>
      </Card>
    </Container>
  );
};

export default Health;
