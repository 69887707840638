import { put, call, all, takeLatest, takeEvery } from 'redux-saga/effects';

import {
  PENDING_QUESTIONS,
  GET_CULTURE_QUESTIONS,
  GET_MOOD_QUESTIONS,
  GET_LEADERSHIP_QUESTIONS,
  GET_PERSONALITY_QUESTIONS,
  PENDING_ALL_MODULAR_QUESTIONS,
  GET_ALL_MODULAR_QUESTIONS,
  SG_GET_ALL_MODULAR_QUESTIONS,
  LOAD_CULTURE_QUESTIONS,
  LOAD_MOOD_QUESTIONS,
  LOAD_LEADERSHIP_QUESTIONS,
  LOAD_PERSONALITY_QUESTIONS,
  PREVIOUS_RESPONSE_PENDING,
  GET_PREVIOUS_RESPONSE,
  LOAD_PREVIOUS_RESPONSE,
  ALL_ERRORS,
} from 'constants/actions.js';

import * as api from 'constants/api.js';

function* getCultureQuestions(action) {
  yield put({ type: PENDING_QUESTIONS });
  try {
    const json = yield call(api.GET_CULTURE_QUESTIONS, action.payload);
    yield put({ type: GET_CULTURE_QUESTIONS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* loadCultureQuestions() {
  yield takeLatest(LOAD_CULTURE_QUESTIONS, getCultureQuestions);
}

function* getModularQuestions(action) {
  yield put({ type: PENDING_QUESTIONS });
  yield put({ type: PENDING_ALL_MODULAR_QUESTIONS });
  try {
    const json = yield call(api.GET_BRIEF_MODULAR_QUESTIONS, action.payload);
    yield put({ type: GET_ALL_MODULAR_QUESTIONS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* loadModularQuestions() {
  yield takeEvery(SG_GET_ALL_MODULAR_QUESTIONS, getModularQuestions);
}

function* getMoodQuestions(action) {
  yield put({ type: PENDING_QUESTIONS });
  try {
    const json = yield call(api.GET_MOOD_QUESTIONS, action.payload);
    yield put({ type: GET_MOOD_QUESTIONS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* loadMoodQuestions() {
  yield takeLatest(LOAD_MOOD_QUESTIONS, getMoodQuestions);
}

function* getLeadershipQuestions(action) {
  yield put({ type: PENDING_QUESTIONS });
  try {
    const json = yield call(api.GET_LEADERSHIP_QUESTIONS, action.payload);
    yield put({ type: GET_LEADERSHIP_QUESTIONS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* loadLeadershipQuestions() {
  yield takeLatest(LOAD_LEADERSHIP_QUESTIONS, getLeadershipQuestions);
}

function* getPersonalityQuestions(action) {
  yield put({ type: PENDING_QUESTIONS });
  try {
    const json = yield call(api.GET_PERSONALITY_QUESTIONS, action.payload);
    yield put({ type: GET_PERSONALITY_QUESTIONS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* loadPersonalityQuestions() {
  yield takeLatest(LOAD_PERSONALITY_QUESTIONS, getPersonalityQuestions);
}

function* getPreviousResponse(action) {
  yield put({ type: PENDING_QUESTIONS });
  yield put({ type: PREVIOUS_RESPONSE_PENDING });
  try {
    const json = yield call(api.GET_PREVIOUS_RESPONSE, action.payload);
    yield put({ type: GET_PREVIOUS_RESPONSE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* loadPreviousResponse() {
  yield takeLatest(LOAD_PREVIOUS_RESPONSE, getPreviousResponse);
}

export default function* index() {
  yield all([
    loadCultureQuestions(),
    loadModularQuestions(),
    loadMoodQuestions(),
    loadLeadershipQuestions(),
    loadPersonalityQuestions(),
    loadPreviousResponse(),
  ]);
}
