import React from "react";
import { Navigate } from "react-router-dom";

import NotFound from "NotFound";
import Health from "health";
// // New Survey Design June 2024
// import CultureAuditV2 from "survey/AuditV2/index.js";
import CultureAuditV3 from "survey/SurveyV3/index.js";

export const routes = [
  {
    path: "/not-found",
    element: <NotFound />,
  },
  // {
  //   path: "/culture-audit-v2/:token",
  //   element: <CultureAuditV2 />,
  // },
  {
    path: "/outcome/employee/:user_token", // validate or show the email
    element: <CultureAuditV3 />,
  },
  {
    path: "/outcome/:token",  // ask for email to proceed further
    element: <CultureAuditV3 />,
  },
  {
    path: "/outcome",
    element: <CultureAuditV3 />,
  },
  {
    path: "health",
    element: <Health />,
  },
  { path: "*", element: <Navigate to="/not-found" /> },
];

export default routes;
